@import "https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Rammetto+One&display=swap";
* {
  font-family: Inter, sans-serif !important;
}

body {
  color: #fff;
  background-color: #242528;
}

.ZiH2ia_menu .ZiH2ia_menuButton {
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, .24);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 12px;
  display: flex;
}

.ZiH2ia_menu .ZiH2ia_menuButton:hover {
  background-color: #3d3e43;
  border-color: rgba(0, 0, 0, 0);
}

.ZiH2ia_menu .ZiH2ia_menuButton.ZiH2ia_isOpen {
  background-color: #3d3e43;
}

@media (min-width: 768px) {
  .ZiH2ia_menu .ZiH2ia_menuButton {
    border: none;
    transition: background-color .2s, -webkit-transform .2s, transform .2s;
  }

  .ZiH2ia_menu .ZiH2ia_menuButton:hover, .ZiH2ia_menu .ZiH2ia_menuButton.ZiH2ia_isOpen {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .ZiH2ia_menu .ZiH2ia_menuButton:active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ZiH2ia_menu .ZiH2ia_menuContent {
  min-width: 190px;
  z-index: 10000000;
  background-color: #3d3e43;
  border-radius: 12px;
  padding: 4px 0;
}

.ZiH2ia_menu .ZiH2ia_menuItem {
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0) !important;
}

.ZiH2ia_menu .ZiH2ia_link {
  grid-gap: 12px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: grid;
}

.ZiH2ia_menuItemText {
  color: #ebecf0;
  letter-spacing: .01em;
  font-size: 16px;
  font-weight: 400;
  transition: color .1s;
}

.ZiH2ia_menuItem:hover .ZiH2ia_menuItemText {
  color: #fff;
}

.ZiH2ia_icon {
  stroke: #ebecf0;
  transition: -webkit-transform .1s, transform .1s;
}

.ZiH2ia_menuButton:hover .ZiH2ia_icon, .ZiH2ia_menuItem:hover .ZiH2ia_icon {
  stroke: #fff;
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.ZiH2ia_hoverIcon {
  display: none;
  stroke: #e41b1b !important;
}

.ZiH2ia_menuButton:hover .ZiH2ia_hoverIcon, .ZiH2ia_menuItem:hover .ZiH2ia_hoverIcon {
  display: block;
}

.ZiH2ia_menuButton:hover .ZiH2ia_notHoverIcon, .ZiH2ia_menuItem:hover .ZiH2ia_notHoverIcon {
  display: none;
}

.ZiH2ia_menu .ZiH2ia_divider {
  height: 2px;
  background-color: #f6d015;
  position: relative;
}

.ZiH2ia_menu .ZiH2ia_divider:before {
  content: "";
  height: 100%;
  width: 50%;
  background-color: #0475ed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@-webkit-keyframes ZiH2ia_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ZiH2ia_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

._0CUdvG_header {
  color: #252524;
  grid-gap: 12px;
  margin-bottom: 12px;
  font-size: 28px;
  font-weight: 700;
  display: grid;
}

@media (min-width: 768px) {
  ._0CUdvG_header {
    margin: 0;
    font-size: 36px;
  }
}

._0CUdvG_top {
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

@media (min-width: 768px) {
  ._0CUdvG_top {
    grid-template-columns: 1fr auto 1fr;
    align-items: flex-start;
  }
}

._0CUdvG_menu {
  margin-left: auto;
}

@media (min-width: 768px) {
  ._0CUdvG_menu {
    align-items: center;
    margin: 0;
    display: flex;
  }
}

._0CUdvG_logo {
  cursor: default;
  grid-gap: 4px;
  text-align: left;
  order: -1;
  display: grid;
}

@media (min-width: 768px) {
  ._0CUdvG_logo {
    text-align: center;
    order: 1;
  }
}

._0CUdvG_first {
  color: #fff;
  text-decoration: line-through;
}

@media (min-width: 768px) {
  ._0CUdvG_first {
    text-transform: uppercase;
  }
}

._0CUdvG_second {
  color: #e41b1b;
}

.y9JOsG_controls {
  position: fixed;
  bottom: 12px;
  left: 16px;
  right: 16px;
}

@media (min-width: 768px) {
  .y9JOsG_controls {
    grid-gap: 16px;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-self: center;
    position: static;
  }
}

.y9JOsG_controlsNav {
  background-color: #3f4042;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  display: flex;
}

@media (min-width: 768px) {
  .y9JOsG_controlsNav {
    grid-gap: 16px;
    border-radius: initial;
    background-color: rgba(0, 0, 0, 0);
    grid-auto-flow: column;
    justify-content: center;
    display: grid;
  }
}

.y9JOsG_controlButton {
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  transition: color .2s, opacity .1s;
}

.y9JOsG_controlButton.y9JOsG_hiddenOnMobile {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 768px) {
  .y9JOsG_controlButton.y9JOsG_hiddenOnMobile {
    opacity: 1;
    pointer-events: initial;
  }
}

.y9JOsG_controlButton:disabled {
  cursor: default;
}

.y9JOsG_shareButton {
  display: block;
}

@media (min-width: 768px) {
  .y9JOsG_shareButton {
    display: none;
  }
}

.y9JOsG_arrowIconWrapper {
  width: 48px;
  height: 48px;
  color: #e41b1b;
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  transition: -webkit-transform .1s, transform .1s, background-color .1s;
  display: flex;
}

@media (min-width: 768px) {
  .y9JOsG_arrowIconWrapper {
    background-color: #ebecf0;
  }
}

.y9JOsG_controlButton:hover .y9JOsG_arrowIconWrapper {
  background-color: #fff;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.y9JOsG_controlButton:active .y9JOsG_arrowIconWrapper {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.y9JOsG_controlButton:disabled .y9JOsG_arrowIconWrapper {
  color: #fff;
  background-color: #797979;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.y9JOsG_controlButton:focus-visible .y9JOsG_arrowIconWrapper {
  outline: 1px auto #e41b1b;
}

.y9JOsG_shareButtonContent {
  width: 112px;
  height: 48px;
  grid-gap: 6px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 20px 12px 16px;
  font-weight: 700;
  display: grid;
}

.y9JOsG_controlButtonText {
  color: #fff;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  transition: color .15s;
  display: none;
}

@media (min-width: 768px) {
  .y9JOsG_controlButtonText {
    color: #ebecf0;
    display: block;
  }

  .y9JOsG_controlButton:hover .y9JOsG_controlButtonText {
    color: #fff;
  }
}

.y9JOsG_controlButton:disabled .y9JOsG_controlButtonText {
  color: #a2a3aa;
}

.y9JOsG_arrows {
  grid-gap: 20px;
  grid-auto-flow: column;
  display: grid;
}

@media (min-width: 768px) {
  .y9JOsG_arrows {
    grid-gap: 16px;
  }
}

.JQqsqq_slidesCountIndicator {
  grid-gap: 2px;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  -webkit-animation: JQqsqq_fadeIn .3s ease-in;
  animation: JQqsqq_fadeIn .3s ease-in;
  display: grid;
}

.JQqsqq_countIndicatorWrapper {
  cursor: pointer;
  padding: 2px;
}

.JQqsqq_countIndicator {
  width: 10px;
  height: 10px;
  background-color: rgba(24, 24, 24, .54);
  border: 1px solid rgba(24, 24, 24, .18);
  border-radius: 50%;
  transition: background-color .2s, -webkit-transform .2s, transform .2s;
  display: block;
}

.JQqsqq_isActive .JQqsqq_countIndicator {
  background-color: #e41b1b;
}

.JQqsqq_countIndicator:hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  background-color: #f19797 !important;
}

.JQqsqq_countIndicator:active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

@-webkit-keyframes JQqsqq_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes JQqsqq_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.jBDAiG_root {
  color: #ebecf0;
  z-index: 1;
  background-color: #333;
  border: 1px solid #4e4e4e;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 13px;
}

.jBDAiG_arrow path {
  fill: #333;
}

.a4Nfeq_loader {
  height: 60px;
  width: 60px;
  -webkit-animation: a4Nfeq_spin .5s steps(10, end) infinite;
  animation: a4Nfeq_spin .5s steps(10, end) infinite;
  position: relative;
}

@-webkit-keyframes a4Nfeq_spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes a4Nfeq_spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.a4Nfeq_loader span {
  height: 25%;
  width: 5%;
  -webkit-transform: translate(-50%, -50%) rotate(calc(36 * var(--index)  * 1deg) ) translate(0, 100%);
  transform: translate(-50%, -50%) rotate(calc(36 * var(--index)  * 1deg) ) translate(0, 100%);
  opacity: calc(var(--index)  / 10);
  background-color: #e41b1b;
  border-radius: 50% 50% 0 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.aKwgaa_card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  flex-direction: column;
  grid-template-rows: max-content 1fr;
  margin: 0 auto;
  transition: -webkit-transform .2s ease-out, transform .2s ease-out;
  display: grid;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .aKwgaa_card {
    width: 77%;
    height: 88%;
    flex-flow: row;
    margin: 24px auto;
    display: flex;
  }
}

.aKwgaa_cardLeft {
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .aKwgaa_cardLeft {
    width: 50%;
  }
}

.aKwgaa_loader {
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.aKwgaa_cardArrow {
  height: fit-content;
  color: #e41b1b;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 12px;
  transition: -webkit-transform .1s, transform .1s, background-color .1s;
  -webkit-animation: aKwgaa_fadeIn .3s ease-in;
  animation: aKwgaa_fadeIn .3s ease-in;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .aKwgaa_cardArrow {
    padding: 8px;
  }
}

.aKwgaa_cardArrow:hover {
  background-color: #fff;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}

.aKwgaa_cardArrow:active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.aKwgaa_cardArrow:disabled {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.aKwgaa_cardArrowIcon {
  transition: opacity .3s;
}

.aKwgaa_cardArrow:disabled .aKwgaa_cardArrowIcon {
  opacity: .5;
}

.aKwgaa_cardArrowLeft {
  left: 0;
}

@media (min-width: 768px) {
  .aKwgaa_cardArrowLeft {
    left: 4px;
  }
}

.aKwgaa_cardArrowRight {
  right: 0;
}

@media (min-width: 768px) {
  .aKwgaa_cardArrowRight {
    right: 4px;
  }
}

.aKwgaa_cardImageWrapper {
  width: 100%;
  height: 100%;
  max-height: 60vh;
  min-height: 25vh;
  cursor: default;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.aKwgaa_hasMultipleSlides .aKwgaa_cardImageWrapper {
  cursor: pointer;
}

@media (min-width: 768px) {
  .aKwgaa_cardImageWrapper {
    max-height: initial;
  }
}

.aKwgaa_media {
  max-width: 100%;
  max-height: 66vh;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 2px;
  -webkit-animation: aKwgaa_fadeIn .3s ease-in;
  animation: aKwgaa_fadeIn .3s ease-in;
}

@media (min-width: 768px) {
  .aKwgaa_media {
    max-height: 100%;
  }
}

.aKwgaa_slidesCountIndicator {
  margin-top: 12px;
}

.aKwgaa_translation {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .aKwgaa_translation {
    width: 50%;
    background-color: #3d3e43;
  }
}

.aKwgaa_translationTextWrapper {
  height: 100%;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .aKwgaa_translationTextWrapper:before {
    content: "";
    height: 20px;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to top, rgba(61, 62, 67, 0), #3d3e43);
    position: absolute;
    top: 0;
    left: 0;
    right: 8px;
  }

  .aKwgaa_translationTextWrapper:after {
    content: "";
    height: 20px;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(rgba(61, 62, 67, 0), #3d3e43);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 8px;
  }
}

.aKwgaa_translationText {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  color: #f4f5f6;
  padding: 12px 16px 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .aKwgaa_translationText {
    padding: 12px 16px;
  }
}

.aKwgaa_translation:hover .aKwgaa_translationText {
  color: #fff;
}

.aKwgaa_translationText::selection {
  color: #fff;
  background-color: #e03c4e;
}

.aKwgaa_translationBottom {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  padding: 0 16px 12px;
  display: none;
}

@media (min-width: 768px) {
  .aKwgaa_translationBottom {
    display: flex;
  }
}

.aKwgaa_shareButton {
  min-width: 120px;
  text-align: center;
  color: #e41b1b;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  outline: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: background-color .3s;
}

@media (min-width: 768px) {
  .aKwgaa_shareButton {
    background: #ebecf0;
  }
}

.aKwgaa_shareButton:hover {
  background-color: #fff;
}

.aKwgaa_shareButton:focus-visible {
  outline: 1px auto #e41b1b;
}

.aKwgaa_shareContent {
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: grid;
}

.aKwgaa_sourceBlock {
  grid-gap: 4px;
  margin-right: 8px;
  display: grid;
}

.aKwgaa_sourceTitle {
  color: #a2a3aa;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.aKwgaa_sourceLink {
  color: #ef7676;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
}

.aKwgaa_tooltip {
  grid-gap: 8px;
  letter-spacing: .01em;
  justify-content: center;
  align-items: center;
  display: grid;
}

.aKwgaa_tooltipTitle {
  letter-spacing: .03em;
  font-weight: 700;
}

@-webkit-keyframes aKwgaa_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes aKwgaa_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.o6Gqiq_progressLoader {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.o6Gqiq_progressBar {
  height: 2px;
  width: 100%;
  overflow: hidden;
}

.o6Gqiq_progressValue {
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0;
  transform-origin: 0;
  background-color: #fff;
  border-radius: 0 25% 25% 0;
  -webkit-animation: o6Gqiq_indeterminateAnimation 1s linear infinite;
  animation: o6Gqiq_indeterminateAnimation 1s linear infinite;
}

@-webkit-keyframes o6Gqiq_indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0%)scaleX(0%);
    transform: translateX(0%)scaleX(0%);
  }

  40% {
    background-color: #e41b1b;
    -webkit-transform: translateX(0%)scaleX(40%);
    transform: translateX(0%)scaleX(40%);
  }

  100% {
    -webkit-transform: translateX(100%)scaleX(50%);
    transform: translateX(100%)scaleX(50%);
  }
}

@keyframes o6Gqiq_indeterminateAnimation {
  0% {
    -webkit-transform: translateX(0%)scaleX(0%);
    transform: translateX(0%)scaleX(0%);
  }

  40% {
    background-color: #e41b1b;
    -webkit-transform: translateX(0%)scaleX(40%);
    transform: translateX(0%)scaleX(40%);
  }

  100% {
    -webkit-transform: translateX(100%)scaleX(50%);
    transform: translateX(100%)scaleX(50%);
  }
}

.xx-39G_posts {
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  flex-direction: column;
  grid-template-rows: min-content auto 32px;
  padding: 12px 16px;
  display: grid;
  overflow: hidden;
}

@media (min-width: 768px) {
  .xx-39G_posts {
    max-height: initial;
    justify-content: space-between;
    padding: 12px 16px;
    display: flex;
  }
}

.xx-39G_error {
  width: fit-content;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #e41b1b;
  border-radius: 24px;
  margin: auto;
  padding: 16px;
  font-size: 20px;
  -webkit-animation: xx-39G_fadeIn .5s ease-in;
  animation: xx-39G_fadeIn .5s ease-in;
}

@-webkit-keyframes xx-39G_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes xx-39G_fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.GR-adW_about {
  width: 100vw;
  grid-template-rows: min-content auto;
  padding: 12px 16px 0;
  display: grid;
}

@media (min-width: 768px) {
  .GR-adW_about {
    height: 100vh;
  }
}

.GR-adW_content {
  margin-top: 12px;
  padding-bottom: 12px;
  overflow-y: auto;
}

.GR-adW_text {
  color: #f4f5f7;
  letter-spacing: .01em;
  line-height: 28px;
}

.GR-adW_text::selection {
  color: #fff;
  background-color: #e03c4e;
}

.GR-adW_section {
  width: 90%;
  margin: 20px auto 0;
}

@media (min-width: 768px) {
  .GR-adW_section {
    width: 70%;
  }
}

.GR-adW_sectionTitle {
  text-align: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

.GR-adW_sectionTitle::selection {
  color: #fff;
  background-color: #e03c4e;
}

.GR-adW_link {
  word-break: break-all;
  color: #fff;
  border-bottom: 1px solid #e03c4e;
  text-decoration: none;
  transition: color .2s, background-color .2s;
}

.GR-adW_link:hover {
  color: #fff;
  background-color: #e03c4e;
}

.GR-adW_link::selection {
  color: #fff;
  background-color: #e03c4e;
}

.GR-adW_linkItem {
  display: block;
}

@media (min-width: 768px) {
  .GR-adW_linkItem {
    display: inline;
  }
}

.NyvnrG_root {
  width: 100vw;
}

@media (min-width: 768px) {
  .NyvnrG_root {
    height: 100vh;
  }
}

.szh-menu-container {
  width: 0;
  height: 0;
  position: relative;
}

.szh-menu {
  box-sizing: border-box;
  width: max-content;
  z-index: 100;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
}

.szh-menu:focus {
  outline: none;
}

.szh-menu--state-closed {
  display: none !important;
}

.szh-menu__arrow {
  box-sizing: border-box;
  width: .75rem;
  height: .75rem;
  z-index: -1;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, .1);
  position: absolute;
}

.szh-menu__arrow--dir-left {
  right: -.375rem;
  -webkit-transform: translateY(-50%)rotate(135deg);
  transform: translateY(-50%)rotate(135deg);
}

.szh-menu__arrow--dir-right {
  left: -.375rem;
  -webkit-transform: translateY(-50%)rotate(-45deg);
  transform: translateY(-50%)rotate(-45deg);
}

.szh-menu__arrow--dir-top {
  bottom: -.375rem;
  -webkit-transform: translateX(-50%)rotate(-135deg);
  transform: translateX(-50%)rotate(-135deg);
}

.szh-menu__arrow--dir-bottom {
  top: -.375rem;
  -webkit-transform: translateX(-50%)rotate(45deg);
  transform: translateX(-50%)rotate(45deg);
}

.szh-menu__item {
  cursor: pointer;
}

.szh-menu__item:focus {
  outline: none;
}

.szh-menu__item--hover {
  background-color: #ebebeb;
}

.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}

.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}

.szh-menu__submenu {
  position: relative;
}

.szh-menu__group {
  box-sizing: border-box;
}

.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}

.szh-menu__divider {
  height: 1px;
  background-color: rgba(0, 0, 0, .12);
  margin: .5rem 0;
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  -webkit-user-select: none;
  user-select: none;
  color: #212529;
  min-width: 10rem;
  border: none;
  border-radius: .25rem;
  padding: .5rem 0;
  box-shadow: 0 3px 7px rgba(0, 0, 0, .133), 0 .6px 2px rgba(0, 0, 0, .1);
}

.szh-menu__item {
  align-items: center;
  padding: .375rem 1.5rem;
  display: flex;
  position: relative;
}

.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: .15s;
  transition-timing-function: ease-in-out;
}

.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}

.szh-menu__item--type-radio:before {
  content: "○";
  font-size: .8rem;
  position: absolute;
  top: .55rem;
  left: .8rem;
}

.szh-menu__item--type-radio.szh-menu__item--checked:before {
  content: "●";
}

.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}

.szh-menu__item--type-checkbox:before {
  position: absolute;
  left: .8rem;
}

.szh-menu__item--type-checkbox.szh-menu__item--checked:before {
  content: "✔";
}

.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}

.szh-menu__submenu > .szh-menu__item:after {
  content: "❯";
  position: absolute;
  right: 1rem;
}

.szh-menu__header {
  color: #888;
  text-transform: uppercase;
  padding: .2rem 1.5rem;
  font-size: .8rem;
}

@-webkit-keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
  }
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
  }
}

@-webkit-keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
  }
}

@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
  }
}

@-webkit-keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    -webkit-transform: translateX(-.75rem);
    transform: translateX(-.75rem);
  }
}

@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    -webkit-transform: translateX(-.75rem);
    transform: translateX(-.75rem);
  }
}

@-webkit-keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    -webkit-transform: translateX(-.75rem);
    transform: translateX(-.75rem);
  }
}

@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    -webkit-transform: translateX(-.75rem);
    transform: translateX(-.75rem);
  }
}

@-webkit-keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    -webkit-transform: translateY(.75rem);
    transform: translateY(.75rem);
  }
}

@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    -webkit-transform: translateY(.75rem);
    transform: translateY(.75rem);
  }
}

@-webkit-keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    -webkit-transform: translateY(.75rem);
    transform: translateY(.75rem);
  }
}

@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    -webkit-transform: translateY(.75rem);
    transform: translateY(.75rem);
  }
}

@-webkit-keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(-.75rem);
    transform: translateY(-.75rem);
  }
}

@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    -webkit-transform: translateY(-.75rem);
    transform: translateY(-.75rem);
  }
}

@-webkit-keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    -webkit-transform: translateY(-.75rem);
    transform: translateY(-.75rem);
  }
}

@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    -webkit-transform: translateY(-.75rem);
    transform: translateY(-.75rem);
  }
}

.szh-menu--state-opening.szh-menu--dir-left {
  -webkit-animation: szh-menu-show-slide-left .15s ease-out;
  animation: szh-menu-show-slide-left .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  -webkit-animation: szh-menu-hide-slide-left .15s ease-in forwards;
  animation: szh-menu-hide-slide-left .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-right {
  -webkit-animation: szh-menu-show-slide-right .15s ease-out;
  animation: szh-menu-show-slide-right .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  -webkit-animation: szh-menu-hide-slide-right .15s ease-in forwards;
  animation: szh-menu-hide-slide-right .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-top {
  -webkit-animation: szh-menu-show-slide-top .15s ease-out;
  animation: szh-menu-show-slide-top .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  -webkit-animation: szh-menu-hide-slide-top .15s ease-in forwards;
  animation: szh-menu-hide-slide-top .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  -webkit-animation: szh-menu-show-slide-bottom .15s ease-out;
  animation: szh-menu-show-slide-bottom .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  -webkit-animation: szh-menu-hide-slide-bottom .15s ease-in forwards;
  animation: szh-menu-hide-slide-bottom .15s ease-in forwards;
}

/*# sourceMappingURL=index.5c704891.css.map */
