@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Rammetto+One&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
}

body {
  background-color: #242528;
  color: #fff;
}
